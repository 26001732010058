<template lang="html">
  <div v-if="enabled">

    <div
      v-for="message in dialog.messages"
      class="message mb-3 w-50"
      :class="{
        'message-employee': message.author_id.$oid == dialog.employee_id.$oid
      }">
      <div class="d-flex">
        <div class="mr-3">
          <img :src="message.author_avatar" height="50" class="rounded-circle">
        </div>
        <div class="">
          <div>
            <span class="font-weight-bold">{{ message.author_name }}</span>
            <small>{{ message.created_at | moment("from", "now", true) }} <small>назад</small></small>
          </div>
          {{ message.text }}
        </div>
      </div>
    </div>

    <form @submit.prevent="sendMessage">
      <div class="form-group">
        <textarea
          required
          v-model="text"
          class="form-control"
          rows="3"></textarea>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary transition-3d-hover">Отправить сообщение</button>
      </div>
    </form>

  </div>
</template>

<script>
const audio = require('static/notify.wav')
export default {
  data () {
    return {
      dialog: {
        messages: []
      },
      employee_id: null,
      visitor_id: null,
      text: null,
      notifySound: new Audio(audio),
      enabled: true
    }
  },

  channels: {
    ChatChannel: {
      connected () {
        console.log('ChatChannel connected')
      },
      rejected () {},
      received (data) {
        console.log('received', data)
        if (!this.dialog._id) {
          this.dialog = data.dialog
        } else {
          this.dialog.messages.push(data.message)
        }
        if (this.dialog.employee_id.$oid === data.message.author_id.$oid) {
          this.notifySound.play()
        }
      },
      disconnected () {}
    }
  },

  methods: {

    sendMessage () {
      this.$cable.perform({
        channel: 'ChatChannel',
        action: 'send_message',
        data: {
          text: this.text,
          dialog_id: this.dialog._id ? this.dialog._id.$oid : null,
          employee_id: this.employee_id,
          visitor_id: this.visitor_id,
          from: this.visitor_id
        }
      })
      this.text = null
    },

    async getDialog () {
      try {
        const response = await this.$http.get(`/api/dialogs/get_dialog?employee_id=${this.employee_id}&visitor_id=${this.visitor_id}`)
        if (response.status === 200 && response.body.status === 'success' && response.body.dialog) {
          this.dialog = response.body.dialog
        }
      } catch (e) {
        this.$toasted.error(`${e.name}: ${e.message}`)
      }
    },

    // async sendMessage () {
    //   try {
    //     let url
    //     if (this.dialog._id) {
    //       url =`/api/dialogs/${this.dialog._id.$oid}/add_message`
    //     } else {
    //       url = `/api/dialogs/add_message?employee_id=${this.employee_id}&visitor_id=${this.visitor_id}`
    //     }
    //     const response = await this.$http.post(url, {
    //       text: this.text
    //     })
    //     if (response.status === 200 && response.body.status === 'success') {
    //       if (this.dialog._id) {
    //         this.dialog.messages.push(response.body.message)
    //       } else {
    //         this.dialog = response.body.dialog
    //       }
    //       this.text = null
    //     } else {
    //       console.log(`Ошибка: ${response.body.message}`)
    //     }
    //   } catch (e) {
    //     console.log(`${e.name}: ${e.message}`)
    //   }
    // }
  },

  async mounted () {
    try {
      this.employee_id = document.getElementById('dialog').getAttribute('employee_id')
      this.visitor_id = document.getElementById('dialog').getAttribute('visitor_id')
      if (this.employee_id === this.visitor_id) {
        this.enabled = false
      }
      console.log('this.employee_id, this.visitor_id', this.employee_id, this.visitor_id)
      await this.getDialog()

      this.$cable.subscribe({
        channel: 'ChatChannel',
        id: this.visitor_id
        // room: `chat_${this.visitor_id}`
        // room: this.dialog._id.$oid
        // room: 'public'
      })
    } catch (e) {
      this.$toasted.error(`${e.name}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
